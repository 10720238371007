@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

.font-dm-sans {
    font-family: "DM Sans", serif;
}

@font-face {
    font-family: 'Futura Std';
    font-weight: 400;
    src: url('https://thebodymethod.s3.us-west-1.amazonaws.com/FuturaStdMedium.woff') format('woff');
}

@font-face {
    font-family: 'Futura Std';
    font-weight: 800;
    src: url('https://thebodymethod.s3.us-west-1.amazonaws.com/FuturaStdBold.woff') format('woff');
}


@font-face {
    font-family: 'Futura Std Condensed';
    font-weight: 300;
    src: url('https://thebodymethod.s3.us-west-1.amazonaws.com/FuturaStdCondensedLight.woff') format('woff');
}

@font-face {
    font-family: 'Futura Std Condensed';
    font-weight: 400;
    font-style: italic;
    src: url('https://thebodymethod.s3.us-west-1.amazonaws.com/FuturaStdBoldOblique.woff') format('woff');
}

@font-face {
    font-family: 'Futura Std Condensed';
    font-weight: 400;
    src: url('https://thebodymethod.s3.us-west-1.amazonaws.com/FuturaStdCondensed.woff') format('woff');
}

@font-face {
    font-family: 'Futura Std Condensed';
    font-weight: 800;
    src: url('https://thebodymethod.s3.us-west-1.amazonaws.com/FuturaStdCondensedBold.woff') format('woff');
}

@font-face {
    font-family: 'Futura Std Condensed';
    font-weight: 900;
    src: url('https://thebodymethod.s3.us-west-1.amazonaws.com/FuturaStdCondensedExtraBd.woff') format('woff');
}

@font-face {
    font-family: "Francois One";
    src: url("https://tbmcerts.s3.us-west-1.amazonaws.com/FrancoisOne-Regular.woff") format("woff")
}

@font-face {
    font-family: "Futura";
    font-weight: 400;
    src: url("https://tbmcerts.s3.us-west-1.amazonaws.com/futura-light.woff") format("woff")
}

@font-face {
    font-family: "Futura Condensed";
    font-weight: 400;
    src: url("https://tbmcerts.s3.us-west-1.amazonaws.com/futura-light.woff") format("woff")
}

@font-face {
    font-family: "Futura";
    font-weight: 800;
    src: url("https://tbmcerts.s3.us-west-1.amazonaws.com/futura.woff") format("woff")
}

@font-face {
    font-family: "Futura Condensed Extra Bold";
    src: url("https://tbmcerts.s3.us-west-1.amazonaws.com/futura-condensed-extra-bold.woff") format("woff")
}

body {
    font-family: 'Futura Std';
}

.monterrey-locations .card__footer h2 {
    font-family: 'Futura Std Condensed', sans-serif;
    font-weight: 400;
}

.monterrey-landing-class-types__title,
.monterrey-about__title,
.embla__type-name {
    font-family: 'Futura Std Condensed', sans-serif;
    text-transform: uppercase;
    color: #1D1D1B !important;
}

.embla__type-description,
.embla__type-description.gothic-font {
    font-family: 'Futura Std Condensed', sans-serif !important;
    font-weight: 400;
}

.monterrey-degraded-btn, .monterrey-banner__title {
    font-family: 'Futura Std Condensed', sans-serif !important;
    font-weight: 900;
}

.package-card__header, .content__price {
    font-family: 'Futura Std Condensed', sans-serif !important;
    color: #1D1D1B !important;
    font-weight: 800;
}

.package-card__header.text-white {
    color: white !important;
}

.coach-quote {
    font-family: 'Futura Std', sans-serif;
    /* text-transform: uppercase; */
    font-weight: 300;
    font-size: 15px;
}

.head__desc {
    font-family: 'Futura Std Condensed', sans-serif !important;
    font-style: italic;
}

.head__app-name, .head__city {
    font-family: 'Futura Std Condensed', sans-serif !important;
}

body,
span,
li,
div,
p,
.font-body,
.accordion-button,
.nav-link,
.font-poppins {
    /*font-family: "Poppins", sans-serif !important;*/
}

.h2.font-poppins,
.h3.font-poppins {
    text-transform: none;
    font-weight: 300 !important;
}

p,
li {
    font-size: 1.2rem;
}

p.medium {
    font-size: 1rem;
}

.text-sentence {
    font-style: normal;
    text-transform: initial;
}

.font-open-sans {
    font-family: "Open Sans", sans-serif !important;
}

.font-style-italic {
    font-style: italic !important;
}

.futura {
    font-family: "Futura", sans-serif !important;
}

.futura-bold {
    font-family: "Futura Condensed Extra Bold", sans-serif !important;
}

.francois {
    font-family: "Francois One", sans-serif !important;
}

.unstyled {
    font-style: none !important;
}

h1.display-1 {
    font-size: calc(4rem + 4.5vw);
}